<template>
  <div class="xtx-member-aside">
    <div class="user-manage">
      <div class="user-title">
        <img :src="require('/static/icons/user.png')" />
        <h4>用户中心</h4>
      </div>
      <div class="links">
        <router-link :to="'/user/authentication'">商家认证</router-link>
        <a href="javascript:" @click="logout">退出登录</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppMemberSidebar',
  methods: {
    logout () {
      this.$store
        .dispatch('OutLogin')
        .then((response) => {
          if (response.returnCode === '1') {
            this.$store.commit('login/userInfo', {}) // 清空用户信息
            this.$router.push('/login').then(() => {
              // instance.$message({ type: 'success', text: '退出成功' })
            })
          }
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped lang="less">
.xtx-member-aside {
  width: 220px;
  margin-right: 20px;
  border-radius: 2px;
  .user-manage {
    background-color: #fff;
    border: 1px solid #eee;
    .links {
      padding: 0 52px 10px;
    }
    a {
      display: block;
      line-height: 1;
      padding: 15px 0;
      font-size: 14px;
      color: #666;
      position: relative;
      &:hover {
        color: @xtxColor;
      }
      &.exact-active {
        color: @xtxColor;

        &:before {
          display: block;
        }
      }
      &.fuzzy-active {
        color: @xtxColor;

        &:before {
          display: block;
        }
      }
      &:before {
        content: '';
        display: none;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        top: 19px;
        left: -16px;
        background-color: @xtxColor;
      }
    }
    .user-title {
      display: flex;
      padding: 10px 0 20px 10px;
      border-bottom: 1px solid #f6f6f6;
      line-height: 20px;
      color: @xtxColor;
      img {
        height: 20px;
        margin-right: 15px;
      }
      h4 {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
</style>
