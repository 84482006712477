<template>
    <AppMemberLayout v-if="isAuthenticated">
        <div class="home">
            <router-view></router-view>
        </div>
    </AppMemberLayout>
    <AppMemberNominalLayout v-else>
        <div class="home">
            <router-view></router-view>
        </div>
    </AppMemberNominalLayout>
</template>

<script>
import AppMemberLayout from '@/components/AppMemberLayout'
import AppMemberNominalLayout from '@/components/AppMemberNominalLayout'
export default {
	name: 'Home',
    data () {
        return {
            isAuthenticated: false
        }
    },
	components: {
        AppMemberLayout,
        AppMemberNominalLayout
    },
  created () {
    const status = this.$store.state.login.userInfo.status
      if (status < 4) {
          this.isAuthenticated = false
      } else {
          this.isAuthenticated = true
      }
  }
}
</script>

<style lang="less" scoped>
.home {
  :deep(.xtx-carousel) .carousel-btn.prev {
    left: 5px;
  }
  :deep(.xtx-carousel) .carousel-btn.next {
    right: 5px;
  }
}
</style>
