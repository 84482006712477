<template>
  <div class="xtx-member-aside">
    <div class="user-manage">
      <h4>用户中心</h4>
      <div class="links">
        <RouterLink
          to="/user/home"
          exact-active-class="exact-active"
          active-class="fuzzy-active"
        >
          用户中心
        </RouterLink>
      </div>
      <!-- <RouterLink to="/user/home">
                <h4>个人中心</h4>
            </RouterLink> -->
      <!-- <div class="links"> -->
      <!-- [默认]精确匹配激活类名: router-link-exact-active -->
      <!-- 使用 exact-active-class 自定义精确匹配激活类名 -->
      <!-- <RouterLink to="/member/home"
                    exact-active-class="exact-active">个人中心</RouterLink>
      </div> -->
      <h4>我的店铺</h4>
      <div class="links">
        <router-link to="/user/authentication">商家认证</router-link>
				 <RouterLink
          to="/user/shop"
          exact-active-class="exact-active"
          active-class="fuzzy-active"
        >
          店铺信息
        </RouterLink>

      </div>
      <h4>订单中心</h4>
      <div class="links">
        <RouterLink
          to="/user/order"
          exact-active-class="exact-active"
          active-class="fuzzy-active"
        >
          我的订单
        </RouterLink>
        <RouterLink
          to="/user/purchase"
          exact-active-class="exact-active"
          active-class="fuzzy-active"
        >
          我的提单
        </RouterLink>
				 <RouterLink
          to="/user/quotation"
          exact-active-class="exact-active"
          active-class="fuzzy-active"
        >
          我的报价单
        </RouterLink>
        <RouterLink
          to="/user/buying"
          exact-active-class="exact-active"
          active-class="fuzzy-active"
        >
          我的求购
        </RouterLink>
      </div>
      <h4>商品中心</h4>
      <div class="links">
        <RouterLink
          to="/user/merchandise"
          exact-active-class="exact-active"
          active-class="fuzzy-active"
        >
          我的商品
        </RouterLink>
      </div>
      <h4>资金账户</h4>
      <div class="links">
        <router-link
          to="/user/account/balance"
          exact-active-class="exact-active"
          active-class="fuzzy-active">账户余额</router-link>
        <router-link
          to="/user/account/withdraw-list"
          exact-active-class="exact-active"
          active-class="fuzzy-active">提现记录</router-link>
        <router-link
          to="/user/account/frozen-money"
          exact-active-class="exact-active"
          active-class="fuzzy-active">冻结资金</router-link>
      </div>
      <h4>账户绑定</h4>
      <div class="links">
        <router-link
          to="/user/account-bind/forms"
          exact-active-class="exact-active"
          active-class="fuzzy-active">账户绑定</router-link>
          <router-link
          to="/user/account-bind/info"
          exact-active-class="exact-active"
          active-class="fuzzy-active">绑定信息</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppMemberSidebar',
	created () {
 }
}

</script>

<style scoped lang="less">
.xtx-member-aside {
  width: 220px;
  margin-right: 20px;
  border-radius: 2px;
  .user-manage {
    background-color: #fff;
    border: 1px solid #eee;
    h4 {
      font-size: 18px;
      font-weight: 400;
      padding: 20px 52px 5px;
      border-top: 1px solid #f6f6f6;
    }
    .links {
      padding: 0 52px 10px;
    }
    a {
      display: block;
      line-height: 1;
      padding: 15px 0;
      font-size: 14px;
      color: #666;
      position: relative;
      &:hover {
        color: @xtxColor;
      }
      &.exact-active {
        color: @xtxColor;

        &:before {
          display: block;
        }
      }
      &.fuzzy-active {
        color: @xtxColor;

        &:before {
          display: block;
        }
      }
      &:before {
        content: '';
        display: none;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        top: 19px;
        left: -16px;
        background-color: @xtxColor;
      }
    }
  }
}
</style>
