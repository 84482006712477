<template>
    <AppUserLayout>
        <div class="container">
            <AppMemberNominalSidebar />
            <article class="article">
                <slot />
            </article>
        </div>
    </AppUserLayout>
</template>
<script>
import AppMemberNominalSidebar from '@/components/AppMemberNominalSidebar'
import AppUserLayout from '@/components/AppUserLayout'
export default {
    name: 'AppMemberLayout',
    components: { AppUserLayout, AppMemberNominalSidebar },
}
</script>
<style scoped lang="less">
.container {
    display: flex;
    padding-top: 20px;
    margin-bottom: 30px;
    .article {
        width: 1000px;
        position: relative;
    }
}
</style>
