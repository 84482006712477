<template>
  <AppUserLayout>
    <div class="container">
      <AppMemberSidebar />
      <article class="article">
        <slot />
      </article>
    </div>
  </AppUserLayout>
</template>
<script>
import AppMemberSidebar from '@/components/AppMemberSidebar'
import AppUserLayout from '@/components/AppUserLayout'
export default {
  name: 'AppMemberLayout',
  components: { AppUserLayout, AppMemberSidebar },
}
</script>
<style scoped lang="less">
.container {
  display: flex;
  padding-top: 20px;
  margin-bottom: 30px;
  .article {
    width: 1000px;
    position: relative;
  }
}
</style>
