<template>
  <header class="app-header">
    <div class="container">
      <span>用户中心</span>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  components: {},
  computed: {},
  data () {
    return {}
  },
}
</script>

<style scoped lang="less">
.app-header {
  height: 50px;
  background: #d12b23;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .container {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 18px;
    font-weight: 550;
  }
}

.line {
  height: 4px;
  background: #d12b23;
}
</style>
