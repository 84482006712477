<template>
  <div>
    <AppTopNav />
    <AppUserHeader />
    <slot />
    <AppFooter />
  </div>
</template>
<script>
import AppTopNav from '@/components/AppTopNav'
import AppFooter from '@/components/AppFooter'
import AppUserHeader from '@/components/AppUserHeader'
// import { useStore } from "vuex";

export default {
  name: 'LayoutTemplate',
  components: { AppUserHeader, AppFooter, AppTopNav },
  setup () {
    // 首页 分栏头
    // const store = useStore();
    // store.dispatch("category/getCategories");
  },
}
</script>
<style scoped lang="less"></style>
